<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <template v-if="configId == null">
            <h1>Создание конфигурации комплексной модернизации</h1>
          </template>
          <template v-else>
            <h1>Редактирование<br> конфигурации id {{ config.id }}</h1>
            <div class="creation-date">
              Дата создания<span>{{ config.createdDate | dateFormat }}</span>
            </div>
            <div class="creation-date">
              Дата изменения<span v-if="config.lastModifiedDate">{{ config.lastModifiedDate | dateFormat }}</span>
              <span v-else>—</span>
            </div>
          </template>

          <h4>Наименование</h4>
          <v-text-field label="Наименование" v-model="config.content.title"></v-text-field>

          <h4 class="mt-2">Сокращённое наименование</h4>
          <v-text-field label="Сокращённое наименование" v-model="config.content.shortTitle"></v-text-field>

          <h4 class="mt-2">Код</h4>
          <v-text-field label="Уникальный для каждой конфигурации" v-model="config.content.context" :disabled="configId != null"></v-text-field>

          <h4 class="mt-2">Описание</h4>
          <v-text-field label="Описание" v-model="config.content.description"></v-text-field>

          <h4 class="mt-2 mb-2">Файлы</h4>
          <div>
            <v-row class="file-row align-center" v-for="attachment in attachments" :key="attachment.id">
              <v-col cols="12" lg="4">
                <a @click="downloadAttachment(attachment)" download class="file-link">
                  <v-icon class="icon-item icon-file-document-outline"></v-icon>
                  {{ truncateStr(attachment.name, 50) }}
                </a>
              </v-col>
              <v-col cols="12" lg="5" class="d-flex align-center">
                <div class="delete ml-2" @click="deleteAttachment(attachment)">
                  <v-icon class="icon-item icon-delete-outline"></v-icon>
                </div>
              </v-col>
            </v-row>

            <input type="file" ref="attachmentInput" style="display: none" @change="addAttachment()">
            <a @click="$refs['attachmentInput'].click()" class="configAdd">+ добавить файл</a>
          </div>

          <h4 class="mt-8">Параметры мониторинга</h4>
          <template v-if="configId == null">
            <v-btn outlined @click="$refs['fileInput'].click()" class="mt-4">загрузить файл конфигурации</v-btn>
            <input ref="fileInput" style="display: none" type="file" @change="loadJson">
          </template>

          <div class="list config">
            <div v-for="(section, sectionIndex) in config.content.sections" :key="sectionIndex" class="list-item-wrap">
              <v-expansion-panels>
                <v-expansion-panel :key="'section_' + sectionIndex">
                  <v-expansion-panel-header inside @keyup.space.prevent expand-icon="">
                    <div class="list-item">
                      <div class="list-item-name">
                        <button class="toggleSwitch">
                          <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                          </svg>
                          <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                          </svg>
                        </button>
                        <span>
                          <h4 v-if="sectionIndex === 0">Раздел</h4>
                          <q>{{ sectionIndex + 1 }}.</q>
                          <v-text-field v-model="section.name" @click.stop="" label="Наименование раздела"></v-text-field>
                        </span>
                        <div v-if="config.content.sections.length > 1"
                             class="delete"
                             @click.stop="deleteSection(sectionIndex)">
                          <v-icon class="icon-item icon-delete-outline"></v-icon>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-expansion-panels class="justify-start">
                      <v-expansion-panel v-for="(indicator, indicatorIndex) in section.indicators"
                                         :key="'index_' + sectionIndex + '_' + indicatorIndex" class="level2">
                        <div class="list-item-wrap">
                          <v-expansion-panel-header @keyup.space.prevent expand-icon="">
                            <div class="list-item">
                              <div class="list-item-name">
                                <button class="toggleSwitch whiteBg">
                                  <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                                  </svg>
                                  <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                                  </svg>
                                </button>
                                <span>
                                  <h4 v-if="indicatorIndex === 0">Показатель</h4>
                                  <q>{{ sectionIndex + 1 }}.{{ indicatorIndex + 1 }}</q>
                                  <v-text-field v-model="indicator.name" @click.stop="" label="Наименование показателя"></v-text-field>
                                </span>
                                <div v-if="section.indicators.length > 1"
                                     class="delete"
                                     @click.stop="deleteIndicator(sectionIndex, indicatorIndex)">
                                  <v-icon class="icon-item icon-delete-outline"></v-icon>
                                </div>
                              </div>
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content class="level3">
                            <div v-for="(parameter, parameterIndex) in indicator.parameters"
                                 :key="'parameter_' + sectionIndex + '_' + indicatorIndex + '_' + parameterIndex" class="list-item">
                              <div class="list-item-name">
                                <span>
                                  <h4 v-if="parameterIndex === 0">Параметр</h4>
                                  <q>{{ sectionIndex + 1 }}.{{ indicatorIndex + 1 }}.{{ parameterIndex + 1 }}</q>
                                   <v-combobox
                                       v-model="parameter.name"
                                       :items="indicators"
                                       item-text="name"
                                       return-object
                                       label="Наименование параметра"
                                       solo
                                       hide-no-data
                                       hide-details
                                       allow-overflow
                                       class="mb-5"
                                       @input="val => changeParameterName(parameter, val)"
                                   >
                                </v-combobox>

                                </span>
                                <div v-if="indicator.parameters.length > 1"
                                     class="delete"
                                     @click.stop="deleteParameter(sectionIndex, indicatorIndex, parameterIndex)">
                                  <v-icon class="icon-item icon-delete-outline"></v-icon>
                                </div>
                              </div>
                              <div class="list-item-description">
                                <span>
                                  <v-text-field v-model="parameter.id" disabled label="Индикатор (заполняется автоматически)"></v-text-field>
                                </span>
                              </div>
                              <div class="list-item-type">
                                <table>
                                  <tr>
                                    <td>
                                      <v-select
                                          label="Тип параметра"
                                          :items="parametersType"
                                          item-text="title"
                                          item-value="type"
                                          v-model="parameter.type"
                                      ></v-select>
                                    </td>
                                    <td>
                                      <v-checkbox
                                          label="Прикрепление файла обязательно"
                                          v-model="parameter.isFileNeeded"
                                          v-show="parameter.type !== 'FILE'"
                                      ></v-checkbox>
                                    </td>
                                    <td style="width: 300px">
                                      <DateField
                                          labelName="Отчётная дата"
                                          :dateValue="parameter.recommendedEndDate"
                                          @minput="parameter.recommendedEndDate=$event"
                                      ></DateField>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <a class="configAdd" style="margin-left: 0; margin-top: -24px!important;" href="#"
                               @click.prevent="addParameter(indicator)">+ Добавить параметр</a>
                          </v-expansion-panel-content>
                        </div>
                      </v-expansion-panel>
                      <a style="margin-left: 80px;" class="configAdd" href="#" @click.prevent="addIndicator(section)">+ добавить показатель</a>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <a class="configAdd" href="#" @click.prevent="addSection()">+ добавить раздел</a>
          </div>

          <h4 class="mb-2 mt-4">Уровень мониторинга:</h4>
          <v-radio-group v-model="config.content.type" row @change="$forceUpdate()" :disabled="configId != null">
            <v-radio label="Регионы" value="region"></v-radio>
            <v-radio label="ЦЗН" value="czn"></v-radio>
          </v-radio-group>

          <template v-if="config.content.type === 'region'">
            <h4 class="mb-2 mt-4">Регионы</h4>
            <div class="region-list-wrap">
              <div class="region-buttons">
                <v-btn color="primary" outlined @click="selectAllRegions">Выделить все</v-btn>
                <v-btn color="primary" outlined @click="config.content.regions = []">Снять выделения</v-btn>
              </div>
              <div class="region-list">
                <div v-for="(region, regionIndex) in regionList" :key="'regionIndex' + regionIndex">
                  <v-checkbox
                      :label="region.name"
                      :value="region.code"
                      v-model="config.content.regions"
                  ></v-checkbox>
                </div>
              </div>
            </div>
          </template>

          <template v-if="config.content.type === 'czn'">
            <h4 class="mb-2 mt-4">ЦЗН</h4>
            <div class="region-list-wrap">
              <div class="region-buttons">
                <v-btn color="primary" outlined @click="selectAllCZN()">Выделить все</v-btn>
                <v-btn color="primary" outlined @click="deselectAllCZN()">Снять выделение</v-btn>
              </div>
              <div class="region-list">
                <div v-for="(regionWithCZN, regionWithCZNIndex) in $cznDepartmentsByRegionList"
                     :key="regionWithCZNIndex+'indexRegion'" class="list-item-wrap">
                  <v-expansion-panels :accordion="true" :flat="true">
                    <v-expansion-panel :key="regionWithCZN+'index'">
                      <div class="expansion-panel-checkbox">
                        <v-expansion-panel-header inside @keyup.space.prevent expand-icon="">
                          <div class="list-item list-item-name">
                            <button class="toggleSwitch whiteBg">
                              <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                              </svg>
                              <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                              </svg>
                            </button>
                            <div>{{ regionWithCZN.name }}</div>
                          </div>
                        </v-expansion-panel-header>
                        <v-checkbox
                            v-model="allCZNinRegionAreSelected[regionWithCZNIndex]"
                            @click="allCZNinRegionAreSelected[regionWithCZNIndex] = !allCZNinRegionAreSelected[regionWithCZNIndex];
                                        selectCZNinRegion(regionWithCZN, regionWithCZNIndex)"
                            style="margin-right: 7px; margin-top: 8px"
                        ></v-checkbox>
                      </div>

                      <v-expansion-panel-content class="level2">
                        <div v-for="(czn, cznIndex) in regionWithCZN.departments"
                             :key="'cznIndex' + cznIndex + regionWithCZNIndex"
                             class="list-item">
                          <v-checkbox
                              :label="czn.name"
                              :value="czn.id"
                              v-model="config.content.czns"
                              @change="checkCZNinRegion(regionWithCZN, regionWithCZNIndex)"
                          ></v-checkbox>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </div>
            </div>
          </template>

          <div class="save-buttons mt-24">
            <v-btn color="primary" outlined @click="$router.push('/config/standard')">Отмена</v-btn>
            <v-btn :disabled="loading" color="primary" @click="submit">Сохранить изменения</v-btn>
          </div>
        </div>
        <div class="error" style="position: fixed;bottom: 0" v-if="errorText!=null">
          Ошибка: {{errorText}}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
    <loader-dialog :show="loading" msg="Загрузка"></loader-dialog>
  </div>
</template>

<script>
import DateField from "@/components/elements/DateField";
import LoaderDialog from "@/components/elements/LoaderDialog.vue";
import {getDomainConfigs, saveDomainConfig, updateDomainConfig} from "@/modules/api.configs";
import {
  createIndicator,
  getIndicatorsByContextAndIndicatorId,
  getIndicatorsByDomain,
  saveIndicators
} from "@/modules/api.indicators";
import {downloadFile, uploadFile} from "@/modules/api.files";

export default {
  name: "standardMonitoringEdit",
  components: {LoaderDialog, DateField},
  data() {
    return {
      configId: null,
      domain: 'standard_report',
      apiLoaded: false,
      loading: false,
      errorText: null,
      indicators: [],
      attachments: [],
      attachmentsIndicatorId: 'bfa515de-5c54-4d7b-85d5-07bb9d40a17d',
      minDate: '2022-12-01',
      maxDate: '2030-12-31',
      configs: [],
      config: {
        id: null,
        content: {
          title: null,
          shortTitle: null,
          description: null,
          type: "region",
          context: null,
          regions: [],
          czns: [],
          sections: [{
            name: "",
            indicators: [{
              name: "",
              parameters: [{
                id: "",
                name: "",
                isFileNeeded: true,
                recommendedEndDate: null,
                type: "STRING"
              }]
            }]
          }]
        }
      },
      parametersType: [
        {type: 'STRING', title: 'Текст', description: 'Текстовое поле'},
        {type: 'NUMBER', title: 'Число', description: 'Числовое значение'},
        {type: 'DATE', title: 'Дата', description: 'Дата'},
        {type: 'FILE', title: 'Файл', description: 'Файл'},
      ],
      inputRules: [value => !value || value.size < 50000000 || 'Размер файла должен быть менее 50 MB'],
      regionList: null,
      allCZNinRegionAreSelected: [],
    };
  },
  methods: {
    changeParameterName(parameter, val) {
      if (val && typeof val === 'object') {
        parameter.id = val.id
        parameter.name = val.name
      } else {
        parameter.id = null
      }
    },
    async loadConfigs() {
      let req = await getDomainConfigs(this.domain)
      if (req.ok) {
        this.configs = req.payload
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },
    async loadIndicators() {
      let req = await getIndicatorsByDomain(this.domain)
      if (req.ok) {
        this.indicators = req.payload
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },
    deleteSection(sectionIndex) {
      this.config.content.sections.splice(sectionIndex, 1);
      this.$forceUpdate()
    },
    deleteIndicator(sectionIndex, indicatorIndex) {
      const section = this.config.content.sections[sectionIndex];
      if (section && section.indicators[indicatorIndex]) {
        section.indicators.splice(indicatorIndex, 1);
      }
      this.$forceUpdate()
    },
    deleteParameter(sectionIndex, indicatorIndex, parameterIndex) {
      const section = this.config.content.sections[sectionIndex];
      if (section) {
        const indicator = section.indicators[indicatorIndex]
        if (indicator && indicator.parameters[parameterIndex]) {
          indicator.parameters.splice(parameterIndex, 1);
        }
      }
      this.$forceUpdate()
    },

    addSection() {
      if (this.config.content.sections == null) {
        this.config.content.sections = []
      }
      this.config.content.sections.push({
        name: "",
        indicators: [{
          name: "",
          parameters: [{
            id: "",
            name: "",
            isFileNeeded: true,
            type: "STRING",
            recommendedEndDate: null
          }]
        }]
      })
      this.$forceUpdate();
    },
    addIndicator(section) {
      section.indicators.push({
        name: "",
        parameters: [{
          name: "",
          isFileNeeded: true,
          type: "STRING",
          recommendedEndDate: null
        }]
      })
      this.$forceUpdate();
    },
    addParameter(indicator) {
      indicator.parameters.push({
        id: "",
        name: "",
        isFileNeeded: true,
        type: "STRING",
        recommendedEndDate: null,
      })
      this.$forceUpdate();
    },

    async submit() {
      this.loading = true

      try {
        let validStat = this.validateConfig(this.config);
        if (validStat === true) {
          await this.createIndicators()
          if (this.configId == null) {
            let req = await saveDomainConfig(this.domain, this.config.content)
            if (req.ok) {
              this.configId = req.payload.id;
              await this.saveAttachments();
              await this.$router.push("/config/standard");
            }
          } else {
            let req = await updateDomainConfig(this.domain, this.config.id, this.config.content)
            if (req.ok) {
              this.configId = req.payload.id;
              await this.saveAttachments();
              await this.$router.push("/config/standard");
            }
          }
        } else {
          this.errorText = validStat
        }
      } catch(e) {
        console.log(e)
        alert('Ошибка сохранения')
      } finally {
        this.loading = false
      }
    },

    //Досоздает индикаторы, которые не нашлись по названию
    async createIndicators() {
      for (const section of this.config.content.sections) {
        for (const indicator of section.indicators) {
          for (const parameter of indicator.parameters) {
            if (!parameter.id || !parameter.id.length) {
              let dataType = parameter.type;
              if (parameter.type === 'NUMBER') {
                dataType = 'INTEGER';
              }
              if (parameter.type === 'FILE') {
                dataType = 'STRING';
              }

              const indicatorData = {
                domain: {
                  "code": this.domain,
                },
                name: parameter.name,
                dataType: dataType,
              };

              const indicator = (await createIndicator(indicatorData)).payload
              parameter.id = indicator.id
            }
          }
        }
      }
    },

    validateConfig(config) {
      if (config.content.title == null || config.content.title.length < 1) {
        return "Должно быть указано наименование комплексной модернизации"
      }
      if (config.content.shortTitle == null || config.content.shortTitle.length < 1) {
        return "Должно быть указано сокращённое наименование комплексной модернизации"
      }
      if (config.content.context == null || config.content.context.length < 1) {
        return "Должен быть указан код комплексной модернизации"
      }
      if (this.configs.some(c => c.content.context === config.content.context && c.id !== config.id)) {
        return "Указанный код комплексной модернизации уже используется в другой конфигурации"
      }
      let validSections = this.validateSection(config.content.sections);
      if (!(validSections === true)) {
        return validSections
      }
      if (config.content.type === "region") {
        config.content.czns = []
        if (config.content.regions.length === 0) {
          return "Должен быть выбран хотя бы один регион"
        }
      } else if (config.content.type === "czn"){
        config.content.regions = []
        if (config.content.czns.length === 0) {
          return "Должен быть выбран хотя бы один ЦЗН"
        }
      }
      return true
    },

    validateSection(sections) {
      if (sections == null || sections.length < 1) {
        return "Должен быть хотя бы один раздел"
      }
      for (let i = 0; i < sections.length; i++) {
        let section = sections[i];
        if (section.name === "" || section.name.length === 0) {
          return "Должно быть указано наименование раздела " + (i + 1)
        }
        for (let j = 0; j < section.indicators.length; j++) {
          let index = section.indicators[j];
          if (index.name === "" || index.name.length === 0) {
            return "Должно быть указано наименование показателя " + (i + 1) + "." + (j + 1)
          }
          for (let k = 0; k < index.parameters.length; k++) {
            let parameter = index.parameters[k];
            if (parameter.name === "" || parameter.name.length === 0) {
              return "Должно быть указано наименование параметра " + (i + 1) + "." + (j + 1) + "." + (k + 1)
            }
            if (parameter.type === "" || parameter.type.length === 0) {
              return "Должен быть указан тип параметра " + (i + 1) + "." + (j + 1) + "." + (k + 1)
            }
          }
        }
      }
      return true
    },

    loadJson() {
      let file = this.$refs['fileInput'].files[0];
      let reader = new FileReader();
      reader.readAsText(file);
      reader.componentThis = this;
      reader.onload = function () {
        let sections
        try {
          sections = JSON.parse(reader.result)
        } catch (e) {
          reader.componentThis.errorText = "Файл должен содержать конфигурацию в формате JSON"
          return
        }
        let validStat = reader.componentThis.validateSection(sections.sections)
        if (validStat === true) {
          reader.componentThis.config.content.sections = sections.sections
        } else {
          reader.componentThis.errorText = validStat
        }
      };
      this.$refs['fileInput'].value = ""
      reader.onerror = function () {
        alert('Ошибка загрузки файла')
      };
    },

    selectAllRegions() {
      for (let region of this.regionList) {
        this.config.content.regions.push(region.code);
      }
      this.$forceUpdate();
    },
    selectAllCZN() {
      for (let czn of this.$cznDepartmentsList) {
        this.config.content.czns.push(czn.id);
      }
      for (let i = 0; i < this.$cznDepartmentsByRegionList.length; i++) {
        this.allCZNinRegionAreSelected[i] = true;
      }
      this.$forceUpdate();
    },
    deselectAllCZN() {
      this.config.content.czns = [];
      for (let i = 0; i < this.$cznDepartmentsByRegionList.length; i++) {
        this.allCZNinRegionAreSelected[i] = false;
      }
      this.$forceUpdate();
    },
    checkCZNinRegion(regionWithCZN, regionWithCZNIndex) {
      for (let czn of regionWithCZN.departments) {
        if (!this.config.content.czns.includes(czn.id)) {
          this.allCZNinRegionAreSelected[regionWithCZNIndex] = false;
          return
        }
      }
      this.allCZNinRegionAreSelected[regionWithCZNIndex] = true;
      this.$forceUpdate();
    },
    selectCZNinRegion(regionWithCZN, regionWithCZNIndex) {
      if (this.allCZNinRegionAreSelected[regionWithCZNIndex]) {
        for (let czn of regionWithCZN.departments) {
          this.config.content.czns.splice(this.config.content.czns.indexOf(czn.id), 1)
        }
        this.allCZNinRegionAreSelected[regionWithCZNIndex] = false;
      } else {
        for (let czn of regionWithCZN.departments) {
          if (!this.config.content.czns.includes(czn.id)) {
            this.config.content.czns.push(czn.id)
          }
        }
        this.allCZNinRegionAreSelected[regionWithCZNIndex] = true;
      }
      this.$forceUpdate();
    },

    async loadAttachments() {
      let req = await getIndicatorsByContextAndIndicatorId(
          this.domain,
          this.config.content.context,
          this.attachmentsIndicatorId
      )
      if (req.ok) {
        const value = req.payload
        if (value && value.length && value[0].value) {
          this.attachments = JSON.parse(value[0].value)
        }
      } else {
        alert('Ошибка загрузки данных о статических файлах')
      }
    },

    async addAttachment() {
      if (!this.config.content.context) {
        alert("Для прикрепления файлов заполните поле Код")
        return
      }

      this.loading = true
      let file = this.$refs['attachmentInput'].files[0];
      if (file) {
        const uploadedFile = await uploadFile(file)
        this.attachments.push({
          id: uploadedFile.id,
          name: uploadedFile.name,
        })
      }

      this.$refs['attachmentInput'].value = ""
      this.$forceUpdate()
      this.loading = false
    },

    async deleteAttachment(attachment) {
      this.attachments.splice(this.attachments.findIndex(e => e.id === attachment.id), 1);
      this.$forceUpdate()
    },

    async downloadAttachment(attachment) {
      this.loading = true
      await downloadFile(attachment.id, attachment.name)
      this.loading = false
    },

    async saveAttachments() {
      const savedValues = [{
        indicatorId: this.attachmentsIndicatorId,
        context: this.config.content.context,
        regionCode: "",
        value: JSON.stringify(this.attachments),
        type: 'FILES'
      }]
      await saveIndicators(savedValues)
    },

    truncateStr(str, n) {
      if (str.length > (n + 6)) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },
  },

  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  async beforeMount() {
    this.loading = true
    this.configId = this.$route.params.id;
    this.regionList = this.$cznDepartmentsByRegionList;

    await this.loadConfigs();
    await this.loadIndicators();
    if (this.configId != null) {
      this.config = this.configs.find(config => config.id === this.configId)
      await this.loadAttachments();
      if (this.config.content.type === 'czn') {
        for (let i = 0; i < this.$cznDepartmentsByRegionList.length; i++) {
          let regionWithCZN = this.$cznDepartmentsByRegionList[i];
          this.checkCZNinRegion(regionWithCZN, i)
        }
      }
    }

    this.loading = false;
    this.apiLoaded = true;
  }
};
</script>

<style lang="scss">
@import "../../../styles/main.scss";
</style>

<style scoped lang="scss">
.file-input {
  margin-top: 15px;
  .v-file-input {
    max-width: 50%;
  }
}
.file-input + .file-input {
  margin-top: 0;
}

.list-item-description {
  .v-input {
    width: calc(100% - 100px);
    flex-grow: 0;
    margin-left: 30px;
  }
}

.region-list-wrap {
  .region-buttons {
    display: flex;
    margin: 10px 0;
    .btn, button {
      margin-right: 8px;
      margin-left: 0;
      height: 30px;
    }
  }
  .region-list {
    max-height: 45vh;
    overflow-x: auto;
    .v-input--checkbox {
      margin: 0 0;
    }
  }
}

.file-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 20px;

  .v-icon {
    margin-right: 10px;
    color: #0033a0;
  }
}

.list.config {
  margin-top: 8px;
}

.list-item-type {
  margin-right: 68px;
  tr {
    margin-top: -20px;
  }
  span {
    width: 100px;
    margin-left: 30px;
  }
  .v-input {
    width: calc(100% - 100px);
    flex-grow: 0;
    margin-left: 30px;
  }
  .v-select {
    margin-top: -20px;
    margin-bottom: -25px;
    width: 250px;
    .v-label {
      color: $cgray!important;
    }
  }

  .v-input__append-inner {
    margin-top: 14px !important;
  }
}

.list-item.list-item-name {
  display: flex;
  align-items: center;
  .toggleSwitch {
    align-self: center;
  }

}

.list-item-type {
  .v-input--selection-controls {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.v-expansion-panel .level2 {
  padding-left: 40px;
  padding-top: 0;
}

.edit, .delete {
  margin-left: -35px;
}

.file-delete {
  margin-left: 152px !important;
}

.v-expansion-panel-header {
  min-height: 50px !important;
  //padding: 4px 0 8px 0 !important;
}

.region-list .v-expansion-panel-header {
  border-bottom: 1px dashed #E8E8E9;
  &:last-child {
    border-bottom: none;
  }
}

.v-expansion-panel-header--active {
  min-height: 50px !important;
}

//.v-expansion-panel-content {
//  border-bottom: 1px dashed #E8E8E9;
//&:last-child {
//  border-bottom: none;
//}
//}

.expansion-panel-checkbox {
  display: flex;
  align-items: start;
  justify-content: end;
}

</style>

